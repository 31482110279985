import * as React from "react";
import { FastField } from "formik";
import { FormGroup, SelectAdv } from "@lib/components";
import { Button } from "@lib/components";
import { RotateLoader } from "@lib/components";
import { Input } from "@lib/components";
import { Switch } from "@lib/components";
import { UI } from "../../../../../../../core/ui";
import { MobxComponent } from "../../../../../../../mobx/components/index";
import { inject, observer } from "mobx-react";
import { RestaurantForm } from "../../../../../../../mobx/components/restaurant-form";

interface Props {
	close?: () => void;
}
interface State {
	generatingAbacus: boolean;
	generatedAbacus: boolean;
	disableAbacusGenerate: boolean;
	generating: boolean;
	generated: boolean
}

type FormValues = T.Schema.Restaurant.RestaurantSchema["abacus"];

@inject("store") @observer
export class SettingsFormIntegrationAbacus extends MobxComponent<Props, State> {

	initialValues: FormValues;

	constructor(props: Props) {
		super(props);
		this.state = {
			generatingAbacus: false,
			generatedAbacus: false,
			disableAbacusGenerate: false,
			generating: false,
			generated: false
		};
		const r = this.injected.store.restaurant!;
		this.initialValues = r.abacus;
	}

	generateAbacusMenu = async () => {
		//
		this.setState({
			generatingAbacus: true,
			generatedAbacus: false,
			disableAbacusGenerate: true
		}, async () => {
			const _id = this.injected.store.restaurant && this.injected.store.restaurant._id ? this.injected.store.restaurant._id : ""
			// const site_id = this.in
			const result = await this.injected.store.generateAbacusMenu(_id, "")
			this.setState({
				generating: false,
				generated: true
			}, async () => {
				const res = await this.injected.store.api.restaurant({ _id: _id });
				///@ts-ignore
				const { restaurant } = res;
				this.injected.store.setRestaurant(restaurant);
				setTimeout(() => {
					this.setState({
						disableAbacusGenerate: false
					})
				}, 1000);
			})
		})
		//
	}


	render() {
		let openItems = false;
		let openItemsList: any = [];
		const r = this.injected.store.restaurant;
		console.log("r.abacus", )
		
				if (this.initialValues && r?.abacus) {
					// console.log(3)
					openItems = true;
					if (
						// filteredMenus[0]
						// && filteredMenus[0].abacus_open_item_list
						r.abacus.abacus_open_item_list
						&& r.abacus.abacus_open_item_list.length > 0
					)
					{
						// console.log(4)
						const filteredList = r.abacus.abacus_open_item_list.filter(openItem => !['Online Promos', 'Online Fees', 'Online Tips'].includes(openItem.productName))
						openItemsList = filteredList.map(openItemListItem => {
							return {
								value: openItemListItem.productId,
								label: openItemListItem.productName
							}
						});
					}
				}
			// }
		
		return (
			<RestaurantForm<FormValues>
				submit={async (r, values) => {
					r.abacus = values;
					if (!r.abacus) {
						r.abacus = {}
					}
					r.abacus.abacus_open_item_list = this.injected.store.restaurant && this.injected.store.restaurant.abacus ? this.injected.store.restaurant.abacus.abacus_open_item_list : [];
					const update = { $set: { "abacus": r.abacus } };
					return { r, update };
				}}
				initialValues={this.initialValues}
				onSuccess={() => {
					if (this.props.close) {
						this.props.close();
					}
				}}
				onError={() => UI.notification.error("An error occurred")}
				onSuccessMessage="Settings Updated"
			>
				{({ form, error }) => {
					const { isSubmitting, setFieldValue } = form;
					return (
						<div className="p-4">
							<FastField
								name="abacus_api_key"
								render={({ field }: any) => (
									<FormGroup
										title="Abacus API Key"
										help="Your unique Abacus API key. This is provided under your Abacus account settings."
									>
										<Input
											type="text"
											{...field}
											value={field.value || ""}
										/>
									</FormGroup>
								)}
							/>

							<FastField
								name="abacus_status"
								render={({ field }: any) => (
									<FormGroup
										title="Enabled?"
										help="Turning this on will enable the Abacus features in the Menus and automatic sending of orders to your POS."
									>
										<Switch
											id="abacus-enabled-switch"
											checked={field.value || false}
											onChange={(e) =>
												setFieldValue(
													"abacus_status",
													e.target.checked
												)
											}
										/>
									</FormGroup>
								)}
							/>

							<FastField
								name="abacus_open_items"
								render={({ field }: any) => (
									<FormGroup
										title="Open Items (Admin Use Only)"
										help="Do not use. This will break the link to Abacus"
									>
										<Switch
											id="enabled-open-items"
											checked={field.value || false}
											onChange={(e) =>
												setFieldValue(
													"abacus_open_items",
													e.target.checked
												)
											}
										/>
									</FormGroup>
								)}
							/>

							<FastField
								name="abacus_kitchen_display_mode"
								render={({ field }: any) => (
									<FormGroup
										title="Kitchen Display Mode"
										help="Use uppercase text instead of markdown (<bold>...)"
									>
										<Switch
											id="enabled-abacus_kitchen_display_mode"
											checked={field.value || false}
											onChange={(e) =>
												setFieldValue(
													"abacus_kitchen_display_mode",
													e.target.checked
												)
											}
										/>
									</FormGroup>
								)}
							/>


							<FormGroup
								title="Refresh Open Items List"
								// help="Do not use. This will break the link to Abacus"
							>
								<Button full={false} color="primary" type="button"
										disabled={this.state.disableAbacusGenerate}
										style={{ marginBottom: "10px" }}
										onClick={() => {
											this.generateAbacusMenu()
										}}
								>
									{this.state.disableAbacusGenerate && <RotateLoader size={2} color="white" />}
									{!this.state.disableAbacusGenerate && "Refresh the list of open items"}
								</Button>
							</FormGroup>

							<FastField
								name="abacus_default_open_item"
								render={({ field }: any) => (
									<FormGroup
										optional={true}
										title="Default Open Item"
										help="Select the default open item to assign if not set. Note: Only works with KitchenHub"
									>
										<SelectAdv
											type="single"
											options={openItemsList}
											value={field.value}
											onChange={(option: string) => setFieldValue("abacus_default_open_item", option)}
										/>
									</FormGroup>
								)}
							/>

							{error && <FormGroup error={error} />}

							<Button
								full={true}
								color="primary"
								type="submit"
								disabled={isSubmitting}
							>
								{isSubmitting && (
									<RotateLoader size={2} color="white" />
								)}
								{!isSubmitting && "Save"}
							</Button>
						</div>
					);
				}}
			</RestaurantForm>
		);

	}

}
