import React, { useEffect, useState } from 'react';
import { Button, CommonText, FooterSection, SidebarBodySection, SidebarBodySectionTop } from '@lib/components';
import styled, { css } from 'styled-components';
import {
  EightCards,
  FiveCardsOneRow,
  FourCards,
  FourCardsOneRow,
  OneRow,
  SixCards,
  SixCardsOneRow,
  TenCards,
  ThreeCardsOneRow,
  TwelveCards,
  TwoRows,
} from '../bump-icon';
import localStorage from 'store';
import { IoMdClose } from 'react-icons/io';
import { FaDesktop } from 'react-icons/fa';
import { FaTabletAlt } from 'react-icons/fa';
import { MobXProviderContext } from 'mobx-react';

const SidebarWrapper = styled.div<{ isOpen: boolean }>`
  position: absolute;
  height: 100vh;
  background: #fff;
  z-index: 11;
  top: 0;
  right: 0;
  transition: 0.25s;
  ${({ isOpen }) =>
    isOpen
      ? css`
          width: 348px;
        `
      : css`
          width: 0;
          *,
          div {
            display: none;
          }
        `}
`;

const ViewDeviceWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
`;

const ViewDeviceButton = styled.div<{ active: boolean }>`
  display: flex;
  padding: 8px 16px;
  align-items: center;
  min-width: 150px;
  height: 40px;
  border-radius: 4px;
  gap: 8px;
  cursor: pointer;
  ${({ active }) => {
    return active
      ? css`
          background: #fff3f3;
          border: 1px solid #fb2f2f;
          div,
          svg {
            color: #fb2f2f;
          }
        `
      : css`
          background: #f1f4f7;
          border: 1px solid transparent;
          div,
          svg {
            color: #4b4f5e;
          }
        `;
  }}
`;

const ViewModeWrapper = styled.div`
  display: grid;
  grid-template-columns: 148px 148px;
  grid-gap: 16px;
`;

const Card = styled.div<{ active: boolean }>`
  cursor: pointer;
  border-radius: 4px;
  svg {
    width: 100%;
  }
  ${({ active }) => {
    return active
      ? css`
          background: #fff3f3;
          border: 1px solid #fb2f2f;
        `
      : css`
          background: #f1f4f7;
          border: 1px solid transparent;
        `;
  }}
`;
const ViewRowsDisplayWrapper = styled.div`
  display: flex;
  gap: 16px;
`;
const ViewRows = styled.div<{ active: boolean }>`
  cursor: pointer;
  border-radius: 4px;
  padding: 5px;
  svg {
    width: 100%;
  }
  ${({ active }) => {
    return active
      ? css`
          background: #fff3f3;
          border: 1px solid #fb2f2f;
        `
      : css`
          background: #f1f4f7;
          border: 1px solid transparent;
        `;
  }}
`;

const ApplyButtonWrapper = styled.div`
  display: flex;
  padding: 12px 24px 24px 24px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-top: 1px solid #dfdfdf;
  background: #fefefe;
  width: 100%;
  position: absolute;
  bottom: 0;
`;

const SideBarHeader = styled.div`
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  svg {
    cursor: pointer;
  }
`;
const SidebarBody = styled.div`
  padding: 16px;
`;

const SidebarOverlay = styled.div<{ isSidebarOpen: boolean }>`
  position: fixed;
  display: ${({ isSidebarOpen }) => (isSidebarOpen ? 'block' : 'none')};
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
`;

type BumpViewSidebarProps = {
  isOpen: boolean;
  close: () => void;
};

const VIEW_MODES = {
  DESKTOP: 'desktop_view',
  TABLET: 'tablet_view',
};

const DEFAULT_SERVICE = ['pickup', 'delivery', 'dine_in'];

const updateOrdersStore = (store: any, restaurantId: string, page: number, cardNum: number) => {
  const serviceToFilter = localStorage.get('serviceToFilter');
  const orderServiceDisplay = serviceToFilter ? serviceToFilter : DEFAULT_SERVICE;
  return store.api.orders_bump_find({
    page,
    limit: cardNum,
    sort: {
      created: -1,
    },
    query: {
      'config.service': {
        $in: orderServiceDisplay,
      },
      status: {
        $in: ['confirmed'],
      },
      restaurant_id: restaurantId,
    },
  });
};

export const BumpViewSidebar: React.FC<BumpViewSidebarProps> = ({ isOpen, close }) => {
  const [selectedCard, setSelectedCard] = useState(null);
  const [selectRowsDisplay, setSelectRowsDisplay] = useState(1);
  const [selectedViewMode, setSelectedViewMode] = useState(VIEW_MODES.DESKTOP);
  const { store } = React.useContext(MobXProviderContext);

  useEffect(() => {
    const scrollRootElement = document.getElementById('scroll-root');
    if (scrollRootElement && isOpen) {
      scrollRootElement.style.overflowY = 'hidden';
    }
    if (scrollRootElement && !isOpen) {
      scrollRootElement.style.overflowY = 'scroll';
    }
    const savedSettingBumpView = localStorage.get('settingBumpView');
    let savedActiveCard = savedSettingBumpView ? JSON.parse(savedSettingBumpView).card : 4;
    setSelectedCard(savedActiveCard);
  }, [isOpen]);

  const handleCardClick = (value: any) => {
    setSelectedCard(value);
  };
  const handleOneRowClick = (value: number) => {
    setSelectRowsDisplay(value);
  };

  const handleApplyClick = async () => {
    if (selectedCard !== null) {
      const restaurantId = store.restaurant._id;
      const response = await updateOrdersStore(store, restaurantId, 1, selectedCard);
      store.updateOrders({
      items: response.items,
      count: response.count,
      page: 1,
    });
      localStorage.set(
        'settingBumpView',
        JSON.stringify({ view_mode: selectedViewMode, row: selectRowsDisplay, card: selectedCard })
      );
      close();
    }
  };

  const handleSelectDesktopView = () => {
    setSelectedViewMode(VIEW_MODES.DESKTOP);
  };

  const handleSelectTabletView = () => {
    setSelectedViewMode(VIEW_MODES.TABLET);
  };

  const isDesktopSelected = selectedViewMode === VIEW_MODES.DESKTOP;
  const isTabletSelected = selectedViewMode === VIEW_MODES.TABLET;
  
  return (
    <>
      <SidebarOverlay isSidebarOpen={isOpen} />
      <SidebarWrapper isOpen={isOpen}>
        <SideBarHeader>
          <CommonText size="large" weight={600} color="#0C202A">
            Bump Screen View
          </CommonText>
          <IoMdClose size={24} onClick={close} />
        </SideBarHeader>
        <SidebarBodySection className='m-b-5'>
          <SidebarBodySectionTop>
          <CommonText size="small" weight={500} color="#0C202A">
          Select the preferred view for the bump screen
        </CommonText>
          <CommonText size="medium" weight={500} color="#4B4F5E">
            Device
          </CommonText>
          <ViewDeviceWrapper>
            <ViewDeviceButton active={isDesktopSelected} onClick={handleSelectDesktopView}>
              <FaDesktop />
              <CommonText size="semiMedium" weight={600}>
                Desktop View
              </CommonText>
            </ViewDeviceButton>
            <ViewDeviceButton active={isTabletSelected} onClick={handleSelectTabletView}>
              <FaTabletAlt />
              <CommonText size="semiMedium" weight={600}>
                Tablet
              </CommonText>
            </ViewDeviceButton>
          </ViewDeviceWrapper>
          <div style={{ margin: '24px 0 16px 0' }}>
            <CommonText size="medium" weight={500} color="#4B4F5E">
              Rows
            </CommonText>
          </div>
          <ViewRowsDisplayWrapper>
            <ViewRows active={selectRowsDisplay === 1} onClick={() => handleOneRowClick(1)}>
              <OneRow />
            </ViewRows>
            <ViewRows active={selectRowsDisplay === 2} onClick={() => handleOneRowClick(2)}>
              <TwoRows />
            </ViewRows>
          </ViewRowsDisplayWrapper>
          <div style={{ margin: '24px 0 16px 0' }}>
            <CommonText size="medium" weight={500} color="#4B4F5E">
              Layout
            </CommonText>
          </div>
          <ViewModeWrapper>
            {/* Desktop view */}
            {isDesktopSelected &&
              (selectRowsDisplay === 2 ? (
                <>
                  <Card active={selectedCard === 6} onClick={() => handleCardClick(6)}>
                    <SixCards />
                  </Card>
                  <Card active={selectedCard === 8} onClick={() => handleCardClick(8)}>
                    <EightCards />
                  </Card>
                  <Card active={selectedCard === 10} onClick={() => handleCardClick(10)}>
                    <TenCards />
                  </Card>
                  <Card active={selectedCard === 12} onClick={() => handleCardClick(12)}>
                    <TwelveCards />
                  </Card>
                </>
              ) : (
                <>
                  <Card active={selectedCard === 4} onClick={() => handleCardClick(4)}>
                    <FourCardsOneRow />
                  </Card>
                  <Card active={selectedCard === 5} onClick={() => handleCardClick(5)}>
                    <FiveCardsOneRow />
                  </Card>
                  <Card active={selectedCard === 6} onClick={() => handleCardClick(6)}>
                    <SixCardsOneRow />
                  </Card>
                </>
              ))}

            {/* Table view */}
            {isTabletSelected &&
              (selectRowsDisplay === 2 ? (
                <>
                  <Card active={selectedCard === 4} onClick={() => handleCardClick(4)}>
                    <FourCards />
                  </Card>
                  <Card active={selectedCard === 6} onClick={() => handleCardClick(6)}>
                    <SixCards />
                  </Card>
                  <Card active={selectedCard === 8} onClick={() => handleCardClick(8)}>
                    <EightCards />
                  </Card>
                  <Card active={selectedCard === 10} onClick={() => handleCardClick(10)}>
                    <TenCards />
                  </Card>
                  <Card active={selectedCard === 12} onClick={() => handleCardClick(12)}>
                    <TwelveCards />
                  </Card>
                </>
              ) : (
                <>
                  <Card active={selectedCard === 3} onClick={() => handleCardClick(3)}>
                    <ThreeCardsOneRow />
                  </Card>
                  <Card active={selectedCard === 4} onClick={() => handleCardClick(4)}>
                    <FourCardsOneRow />
                  </Card>
                  <Card active={selectedCard === 5} onClick={() => handleCardClick(5)}>
                    <FiveCardsOneRow />
                  </Card>
                </>
              ))}
          </ViewModeWrapper>
          </SidebarBodySectionTop>
        <FooterSection style={{
          width: "348px"
        }}>
          <Button full={true} type="button" color="primary" onClick={handleApplyClick}>
            Apply View
          </Button>
        </FooterSection>
        </SidebarBodySection>
      </SidebarWrapper>
    </>
  );
};
