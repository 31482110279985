import React, { useState, useEffect } from 'react';
import { CommonText } from '@lib/components';
import { MobXProviderContext } from 'mobx-react';

type CountdownClockProps = {
  endTime: number; // in seconds
  finishCountdown: (value: boolean) => void;
};

const formatTime = (totalSeconds: number) => {
  const t = totalSeconds - Date.now()
  const hours = Math.floor((t % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((t % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((t % (1000 * 60)) / 1000);

  return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds
    .toString()
    .padStart(2, '0')}`;
};

const getPauseMins = (totalSeconds: number) => {
  const t = totalSeconds - Date.now()
  const seconds = Math.floor(t / 1000);
  return seconds
};

export const CountdownClock: React.FC<CountdownClockProps> = ({ endTime, finishCountdown }) => {
  const defaultTime = getPauseMins(endTime)
  const [countdownTime, setCountdownTime] = useState(defaultTime);

  if (endTime === 0) {
    return <></>;
  }
  useEffect(() => {
    if (countdownTime > 0) {
      let countdownInterval = setInterval(() => {
        setCountdownTime((prevTime: number) => prevTime - 1);
      }, 1000);

      return () => {
        clearInterval(countdownInterval);
      };
    } else if (countdownTime === 0) {
      finishCountdown(true);
    }
  }, [countdownTime]);

  useEffect(() => {
    setCountdownTime(getPauseMins(endTime));
  }, [endTime]);

  return (
    <CommonText size="semiMedium" weight={500} color="#F5F5F5">
      Time Remaining: {formatTime(endTime)}
    </CommonText>
  );
};
