import * as React from "react";
import { FastField } from "formik";
import { FormGroup } from "@lib/components";
import { Button } from "@lib/components";
import { RotateLoader } from "@lib/components";
import { Input } from "@lib/components";
import { Switch } from "@lib/components";
import { UI } from "../../../../../../../core/ui";
import { MobxComponent } from "../../../../../../../mobx/components/index";
import { inject, observer } from "mobx-react";
import { RestaurantForm } from "../../../../../../../mobx/components/restaurant-form";

interface Props {
	close?: () => void;
}
interface State {
	generatingWPOS: boolean;
	generatedWPOS: boolean;
	disableWPOSGenerate: boolean;
	generating: boolean;
	generated: boolean
}

type FormValues = T.Schema.Restaurant.RestaurantSchema["wpos"];

@inject("store") @observer
export class SettingsFormIntegrationWPOS extends MobxComponent<Props, State> {

	initialValues: FormValues;

	constructor(props: Props) {
		super(props);
		this.state = {
			generatingWPOS: false,
			generatedWPOS: false,
			disableWPOSGenerate: false,
			generating: false,
			generated: false
		};
		const r = this.injected.store.restaurant!;
		this.initialValues = r.wpos;
	}

	generateWPOSMenu = async () => {
		//
		this.setState({
			generatingWPOS: true,
			generatedWPOS: false,
			disableWPOSGenerate: true
		}, async () => {
			const _id = this.injected.store.restaurant && this.injected.store.restaurant._id ? this.injected.store.restaurant._id : ""
			// const site_id = this.in
			const result = await this.injected.store.generateWPOSMenu(_id, "")
			this.setState({
				generating: false,
				generated: true
			}, async () => {
				const res = await this.injected.store.api.restaurant({ _id: _id });
				///@ts-ignore
				const { restaurant } = res;
				this.injected.store.setRestaurant(restaurant);
				setTimeout(() => {
					this.setState({
						disableWPOSGenerate: false
					})
				}, 1000);
			})
		})
		//
	}


	render() {
		return (
			<RestaurantForm<FormValues>
				submit={async (r, values) => {
					r.wpos = values;
					if (!r.wpos) {
						r.wpos = {}
					}
					r.wpos.wpos_open_item_list = this.injected.store.restaurant && this.injected.store.restaurant.wpos ? this.injected.store.restaurant.wpos.wpos_open_item_list : [];
					const update = { $set: { "wpos": r.wpos } };
					return { r, update };
				}}
				initialValues={this.initialValues}
				onSuccess={() => {
					if (this.props.close) {
						this.props.close();
					}
				}}
				onError={() => UI.notification.error("An error occurred")}
				onSuccessMessage="Settings Updated"
			>
				{({ form, error }) => {
					const { isSubmitting, setFieldValue } = form;
					return (
						<div className="p-4">
							<FastField
								name="wpos_api_key"
								render={({ field }: any) => (
									<FormGroup
										title="WPOS API Key"
										help="Your unique WPOS API key. This is provided under your WPOS account settings."
									>
										<Input
											type="text"
											{...field}
											value={field.value || ""}
										/>
									</FormGroup>
								)}
							/>

							<FastField
								name="wpos_status"
								render={({ field }: any) => (
									<FormGroup
										title="Enabled?"
										help="Turning this on will enable the WPOS features in the Menus and automatic sending of orders to your POS."
									>
										<Switch
											id="wpos-enabled-switch"
											checked={field.value || false}
											onChange={(e) =>
												setFieldValue(
													"wpos_status",
													e.target.checked
												)
											}
										/>
									</FormGroup>
								)}
							/>

							{/* <FastField
								name="wpos_open_items"
								render={({ field }: any) => (
									<FormGroup
										title="Open Items (Admin Use Only)"
										help="Do not use. This will break the link to WPOS"
									>
										<Switch
											id="enabled-open-items"
											checked={field.value || false}
											onChange={(e) =>
												setFieldValue(
													"wpos_open_items",
													e.target.checked
												)
											}
										/>
									</FormGroup>
								)}
							/> */}

							{/* <FastField
								name="wpos_kitchen_display_mode"
								render={({ field }: any) => (
									<FormGroup
										title="Kitchen Display Mode"
										help="Use Kitchen Display Mode"
									>
										<Switch
											id="enabled-wpos_kitchen_display_mode"
											checked={field.value || false}
											onChange={(e) =>
												setFieldValue(
													"wpos_kitchen_display_mode",
													e.target.checked
												)
											}
										/>
									</FormGroup>
								)}
							/> */}


							{/* <FormGroup
								title="Refresh Open Items List"
								// help="Do not use. This will break the link to WPOS"
							>
								<Button full={false} color="primary" type="button"
										disabled={this.state.disableWPOSGenerate}
										style={{ marginBottom: "10px" }}
										onClick={() => {
											this.generateWPOSMenu()
										}}
								>
									{this.state.disableWPOSGenerate && <RotateLoader size={2} color="white" />}
									{!this.state.disableWPOSGenerate && "Refresh the list of open items"}
								</Button>
							</FormGroup> */}

							{error && <FormGroup error={error} />}

							<Button
								full={true}
								color="primary"
								type="submit"
								disabled={isSubmitting}
							>
								{isSubmitting && (
									<RotateLoader size={2} color="white" />
								)}
								{!isSubmitting && "Save"}
							</Button>
						</div>
					);
				}}
			</RestaurantForm>
		);

	}

}
